import { imgSrc } from '@/utils/helper'
import { set } from 'lodash'
export default {
  data: () => ({
    imgTarget: null,
    uploadDialog: false,
    uploadImg: null,
  }),
  methods: {
    loadImg (img) {
      this.uploadImg = img
      this.uploadDialog = true
    },

    imgSource (img) {
      return imgSrc(this.imgSize, img)
    },

    getImage (data, formDataImg = true) {
      if (formDataImg) this.formData.img = data
      if (this.imgTarget) {
        set(this.formData, this.imgTarget, data)
      }
      this.imgChanged = true
      this.uploadDialog = false
    },
  },
}
